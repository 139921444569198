import { isBrowser } from '../../Utils'

// Used for preventing modals to close on mouse drag scrollbar
const ScrollbarSafeWidth = 30
export const targetIsScrollbar = (event: any) => {
    if (isBrowser) {
        if (event.x > window.outerWidth - ScrollbarSafeWidth) {
            return true
        }
    }
    return false
}
