import React, {useEffect, useState} from 'react';
import {
    HeroAppProps,
    HeroBgDisplayMode,
    HeroWavePosition,
} from './types';
import Hero from './Hero';

import {I18nextProvider} from "react-i18next";
import i18n from "@apps/Shared/translation/i18n";
import {getLocale} from "@apps/Shared/Utils/Utils";
import {Locales, useGetHeroQuery} from "@apps/Shared/GraphQL/generated";
import {
    mapDisplayMode, mapHeroProps, mapWavePosition, renderHeroBgImage, renderHeroBgVideo, renderHeroImage, renderHeroText
} from "@sharedComponents/Hero/Utils";

function App(props: HeroAppProps): JSX.Element {
    const id = props.id;
    const locale = getLocale();

    const {data: heroData} = useGetHeroQuery({
        id: id,
        locale: Locales.All,
    });

    const [item, setItem] = useState<any>(null);

    useEffect(() => {
        if (heroData?.Content?.items && heroData.Content.items.length > 0) {
            if (heroData.Content.items[0]?.__typename !== 'HeroBlockModel') {
                setItem(heroData.Content.items[0]);
            } else {
                const heroItem = heroData.Content.items.find(i => i?.__typename === 'HeroBlockModel' && i.Language?.Name === locale);
                if (heroItem) {
                    setItem(heroItem);
                }
            }
        }
    }, [heroData]);


    if (item) {
        const hiddenPageInput = document.querySelector('.hero-data-input') as HTMLInputElement;
        let displayMode = HeroBgDisplayMode.High;
        let wavePosition = HeroWavePosition.Top;

        if (hiddenPageInput && Number(hiddenPageInput.value) === id) {
            displayMode = mapDisplayMode(hiddenPageInput.getAttribute('data-display-mode') ?? '');
            wavePosition = mapWavePosition(hiddenPageInput.getAttribute('data-wave-position') ?? '');
        }

        let heroProps = mapHeroProps(item, wavePosition);
        const bgVideo = renderHeroBgVideo(item, displayMode);
        const bgImage = renderHeroBgImage(item, displayMode);
        const text = renderHeroText(item);
        const image = renderHeroImage(item);

        return (
            <I18nextProvider i18n={i18n}>
                <Hero {...heroProps} bgVideo={bgVideo} bgImage={bgImage}>
                    {text}
                    {image}
                </Hero>
            </I18nextProvider>
        );
    } else {
        return (<></>);
    }
}

export default App;