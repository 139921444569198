import React, {FC} from 'react'
import {IModalProps, ModalSectionProps} from './types'
import ModalPortal from './ModalPortal'

const ModalHeader: FC<ModalSectionProps> = ({ children }) => (
    <div className="modal__header">{children}</div>
);

const ModalBody: FC<ModalSectionProps> = ({ children }) => (
    <div className="modal__body">{children}</div>
);

const ModalFooter: FC<ModalSectionProps> = ({ children }) => (
    <div className="modal__footer">{children}</div>
);

const Modal = (props: IModalProps) => {
    return (
        <ModalPortal
            open={props.open}
            handleClose={props.handleClose}
            size={props.size}
            className={props.className}
            role={props.role}
            shouldCloseOnEsc={props.shouldCloseOnEsc}
            shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
            onAfterClose={props.onAfterClose}
            noPadding={props.noPadding}
            domNode={props.domNode}
            disableCloseButton={props.disableCloseButton}
        >
            {props.children}
        </ModalPortal>
    )
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal
