import { Swedish } from 'flatpickr/dist/l10n/sv.js'
import English from 'flatpickr/dist/l10n/default.js'
import join from 'lodash/join'
import includes from 'lodash/includes'
import split from 'lodash/split'
import map from 'lodash/map'
import { isBrowser } from "@apps/Shared/Utils";
import BrowserStorage, { LANGUAGE, StorageType } from "@frontendUtils/browser-storage";
import i18n from "@apps/Shared/translation/i18n";
import { DEFAULT_LANGUAGE } from "@apps/Shared/Constants/Language";
import moment from "moment";

export const RangeSeparator = ` — `
export const DefaultDateFormat = `YYYY-MM-DD`
export const InvalidDate = `Invalid date`
export const DefaultMinDate = `1960-01-01`
export const DefaultMaxDate = `2060-01-01`

export const targetIsDatepicker = (eventTarget: any) => {
    if (!eventTarget) {
        return false
    }
    if (
        eventTarget.classList.contains('flatpickr-day') ||
        eventTarget.classList.contains('flatpickr-next-month') ||
        eventTarget.classList.contains('flatpickr-prev-month') ||
        eventTarget.classList.contains('flatpickr-current-month') ||
        eventTarget.classList.contains('flatpickr-month') ||
        eventTarget.classList.contains('flatpickr-calendar') ||
        eventTarget.classList.contains('numInputWrapper') ||
        eventTarget.classList.contains('flatpickr-weekday') ||
        eventTarget.classList.contains('flatpickr-weekdays') ||
        eventTarget.classList.contains('dayContainer')
    ) {
        return true
    }
    return false
}

export const getLocalizationConfig = () => {
    const storage = isBrowser ? new BrowserStorage(StorageType.LOCAL) : null
    const langFromStorage = storage && storage.getItem(LANGUAGE)
    const currentLang = i18n.language || langFromStorage || DEFAULT_LANGUAGE
    const localization = currentLang === 'sv' ? Swedish : English
    localization.rangeSeparator = RangeSeparator
    return localization
}

export const stringArrayToDateString = (strArr: string[]) => {
    const dateStr = ``
    if (strArr && strArr.length > 0) {
        return join(strArr, RangeSeparator)
    }
    return dateStr
}

export const dateStringToStringArray = (dateStr: string) => {
    let dates = []
    if (includes(dateStr, RangeSeparator)) {
        dates = split(dateStr, RangeSeparator)
    } else {
        dates = [dateStr]
    }
    dates = map(dates, date => {
        const formattedDate = moment(new Date(date)).format(DefaultDateFormat)
        if (formattedDate !== InvalidDate) {
            return formattedDate
        }
        return
    })
    return dates
}