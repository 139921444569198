import React from 'react'
import { HeroTextAlign, HeroTextProps } from './types'

class HeroText extends React.Component<HeroTextProps> {
    render() {
        return (
            <div className={`hero-text ${this.props.textAlign ?? ''} ${this.props.preHeadingFont === 'Pantamera' ? 'preHeadingPantamera' : ''} ${this.props.teaserTextFont === 'Pantamera' ? 'teaserTextPantamera' : ''}`}>
                {this.props.children}
            </div>
        )
    }
}

export default HeroText
