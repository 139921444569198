import React from 'react'
import VideoControls from '../../VideoControls'
import HeroScroll from '../HeroScroll'
import {HeroRatio} from "@sharedComponents/Hero/types";

type HeroBgVideoProps = {
    ratio: string
    src: string
    format: string
    posterSrc?: string
    ariaLabel: string
}

const HeroBgVideo = (props: HeroBgVideoProps) => {
    const videoRef = React.useRef<HTMLVideoElement>(null)

    return (
        <div className={`hero-bg-video ${props.ratio === HeroRatio.Ratio25 ? 'ratio25' : ''}`}>
            <HeroScroll parallax={true}>
                <video
                    ref={videoRef}
                    poster={props.posterSrc || undefined}
                    aria-label={props.ariaLabel}
                    autoPlay
                    muted
                    loop
                >
                    <source src={props.src} type={props.format} />
                </video>
            </HeroScroll>
            <VideoControls className="hero_vid_ctrls" ref={videoRef} />
        </div>
    )
}

export default HeroBgVideo
