import React, { useRef, useEffect } from 'react'
import { resize } from '../../../Utils/Utils'

interface IProps {
    imageSrc: string
    altText: string
}

export const HeroImage = (props: IProps) => {
    const el = useRef(null)

    useEffect(() => {
        if (el && el.current) {
            const image = (el.current as unknown) as HTMLImageElement

            image.src = props.imageSrc

            image.onload = () => {
                resize()
            }
        }
    }, [])

    return (
        <div className={'hero-image'}>
            <img className={'hero-image__image'} ref={el} src={props.imageSrc} alt={props.altText} />
        </div>
    )
}

export default HeroImage
