import {
    ContentImagePosition, ContentTextAlignment,
    HeroBgDisplayMode,
    HeroBlockProps, HeroColorMode,
    HeroColorTheme, HeroRatio, HeroTextPosition,
    HeroWavePosition, IMAGE_FILE_TYPE, VIDEO_FILE_TYPE
} from "@sharedComponents/Hero/types";
import {ImageCmsFile} from "@apps/Shared/Types/ImageFile";
import HeroBgVideo from "@sharedComponents/Hero/HeroBgVideo";
import React from "react";
import HeroBgImage from "@sharedComponents/Hero/HeroBgImage";
import {HeroTextAlign} from "@sharedComponents/Hero/HeroText/types";
import HeroText from "@sharedComponents/Hero/HeroText";
import ButtonLinkBlock from "@sharedComponents/ButtonLinkBlock";
import HeroImage from "@sharedComponents/Hero/HeroImage";

export const mapDisplayMode = (displayMode: string): HeroBgDisplayMode => {
    if (displayMode.toLowerCase() === 'low') {
        return HeroBgDisplayMode.Low;
    }

    return HeroBgDisplayMode.High;
}

export const mapWavePosition = (wavePosition: string): HeroWavePosition => {
    if (wavePosition.toLowerCase() === 'bottom') {
        return HeroWavePosition.Bottom;
    }

    return HeroWavePosition.Top;
}

function renderButtonLinkBlocks(props: HeroBlockProps) {
    if (props.Buttons && props.Buttons.length > 0) {
        return props.Buttons.map(buttonLinkBlock =>
            <ButtonLinkBlock key={buttonLinkBlock.ContentLink.Expanded?.Id} {...buttonLinkBlock.ContentLink.Expanded} />
        );
    }

    return;
}

export function renderHeroText(props: HeroBlockProps) {
    const textAlignment = Object.values(ContentTextAlignment)[Number(props.ContentTextAlignment)];
    if (props.PreHeading || props.Heading || props.TeaserText) {
        const buttonLinkBlocks = renderButtonLinkBlocks(props);
        const textAlign =
            textAlignment === ContentTextAlignment.Left
                ? HeroTextAlign.Left
                : textAlignment === ContentTextAlignment.Center
                    ? HeroTextAlign.Center
                    : HeroTextAlign.Right;

        return (
            <HeroText
                textAlign={textAlign}
                preHeadingFont={props.PreHeadingFont}
                teaserTextFont={props.TeaserTextFont}
            >
                <span>{props.PreHeading}</span>
                {props.HeroIsHeadingH1 ? (
                    <h1>{props.Heading}</h1>
                ) : (
                    <span className="heading">{props.Heading}</span>
                )}
                <p>{props.TeaserText}</p>
                {buttonLinkBlocks}
            </HeroText>
        );
    }

    return;
}

export function renderHeroImage(props: HeroBlockProps) {
    if (
        props.Image &&
        props.Image.Expanded &&
        props.Image.Expanded.__typename === IMAGE_FILE_TYPE
    ) {
        const altText =
            props.Image.Expanded.AltText ||
            props.Heading ||
            props.Image.Expanded.Name;
        return (
            <HeroImage imageSrc={props.Image.Expanded.Url} altText={altText}/>
        );
    }

    return;
}

export function renderHeroBgImage(props: HeroBlockProps, displayMode: HeroBgDisplayMode) {
    if (props.__typename === IMAGE_FILE_TYPE && props.Url) {
        return (
            <HeroBgImage
                src={props.Url}
                ratio={
                    displayMode === HeroBgDisplayMode.Low
                        ? HeroRatio.Ratio25
                        : HeroRatio.Ratio45
                }
                altText={props.Background?.Expanded?.AltText ?? ''}
            ></HeroBgImage>
        );
    }

    return;
}


export function renderHeroBgVideo(props: HeroBlockProps, displayMode: HeroBgDisplayMode) {
    if (props.__typename === VIDEO_FILE_TYPE && props.Url) {
        let posterImage;

        if (props.PreviewImage && props.PreviewImage.Expanded) {
            posterImage = props.PreviewImage.Expanded as ImageCmsFile;
        }

        return (
            <HeroBgVideo
                src={props.Url}
                format="video/mp4"
                ratio={
                    displayMode === HeroBgDisplayMode.Low
                        ? HeroRatio.Ratio25
                        : HeroRatio.Ratio45
                }
                posterSrc={posterImage ? posterImage.Url : ''}
                ariaLabel={props.Background?.Expanded?.AltText ?? ''}
            ></HeroBgVideo>
        );
    }

    return;
}

export function mapHeroProps(props: HeroBlockProps, wavePosition?: HeroWavePosition | undefined) {
    const imagePosition = Object.values(ContentImagePosition)[Number(props.ContentImagePosition)]
    const colorTheme = Object.values(HeroColorTheme)[Number(props.ColorTheme)];
    return {
        bgImageSrc:
            props.Background &&
            props.Background.Expanded &&
            props.Background.Expanded.__typename === IMAGE_FILE_TYPE
                ? props.Background.Expanded.Url
                : '',
        bgVideoSrc:
            props.Background &&
            props.Background.Expanded &&
            props.Background.Expanded.__typename === VIDEO_FILE_TYPE
                ? props.Background.Expanded.Url
                : '',
        textPosition:
            imagePosition === ContentImagePosition.Left
                ? HeroTextPosition.Right
                : HeroTextPosition.Left,
        wave: wavePosition || HeroWavePosition.Top,
        noMargin: true,
        parallax: true,
        colorMode:
            colorTheme === HeroColorTheme.LightBg
                ? HeroColorMode.Light
                : HeroColorMode.Dark,
        altText: props.Heading || '',
    }
}