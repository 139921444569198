import React from 'react';
import {createRoot} from 'react-dom/client';
import HeroApp from "./HeroApp";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const heroBlocks = document.querySelectorAll('.js-hero-block');

if (heroBlocks.length) {
    heroBlocks.forEach((heroBlock) => {
        const hiddenHeroInput = heroBlock.querySelector('input');
        const id = Number((hiddenHeroInput as HTMLInputElement).value);
        const container = document.getElementById(`js-hero-block-${id}`);
        if (container && id) {
            const root = createRoot(container);
            const queryClient = new QueryClient();
            root.render(
                <QueryClientProvider client={queryClient}>
                    <HeroApp id={id}/>
                </QueryClientProvider>
            );
        }
    });
}