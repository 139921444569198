import React from 'react'
import HeroScroll from '../HeroScroll'
import {HeroRatio} from "@sharedComponents/Hero/types";

type HeroBgImageProps = {
    src: string
    ratio: string
    altText: string
}

const HeroBgImage = (props: HeroBgImageProps) => {
    return (
        <div className={`hero-bg-image ${props.ratio === HeroRatio.Ratio25 ? 'ratio25': ''}`}>
            <HeroScroll parallax={true}>
                <img className={`hero-bg-image__image`} src={props.src} alt={props.altText} />
            </HeroScroll>
        </div>
    )
}

export default HeroBgImage
