import { ReactNode } from 'react'

export enum ModalSize {
    ExtraSmall = 'xs',
    Medium = 'md',
    Large = 'lg',
    ExtraLarge = 'xl',
    XXL = 'xxl',
}

export interface IModalProps {
    open: boolean
    className?: string
    role?: string
    shouldCloseOnEsc?: boolean
    shouldCloseOnOverlayClick?: boolean
    size?: string
    handleClose?: any
    onAfterClose?: any
    children: ReactNode
    noPadding?: boolean
    domNode?: HTMLElement
    disableCloseButton?: boolean
}

export interface ModalSectionProps {
    children?: ReactNode;
}
