import React, { useRef, useEffect } from 'react'
import { HeroScrollProps } from './types'

const HeroScroll = (props: HeroScrollProps) => {
    const wrapper = useRef(null)
    const content = useRef(null)
    const overlay = useRef(null)

    function initScroll(el: HTMLElement) {
        if (!props.parallax) {
            return
        }

        const contentEl = content.current as any
        const overlayEl = overlay.current as any

        if (!contentEl || !overlayEl) {
            return
        }

        let posY = 0 // Position of content element from top

        function onScroll() {
            const d = posY - window.scrollY

            const newSticky = d > 0 ? 0 : d * -1

            contentEl.style.transform = `translateY(${newSticky}px)`

            if (
                (typeof props.overlay === 'undefined' || props.overlay) &&
                newSticky > 0
            ) {
                overlayEl.style.opacity = '.55'
            } else {
                overlayEl.style.opacity = '0'
            }
        }

        function onResize() {
            if (posY === 0) {
                // @todo Something is causing the initial posY to become offset
                // by approx. 20 pixels. This causes a glitch in the parallax
                // background which gets a white bar at the top. The "+ 20"
                // magic number takes this offset into account and
                // prevents that. A more robust solution should
                // be implemented in the future.
                posY = posY =
                    el.getBoundingClientRect().top + window.scrollY + 20
            } else {
                posY = el.getBoundingClientRect().top + window.scrollY
            }

            onScroll()
        }

        window.addEventListener('resize', onResize)

        window.addEventListener('scroll', onScroll, {
            passive: true,
        })

        onResize() // Initial resize, will also trigger onScroll()

        return () => {
            window.removeEventListener('scroll', onScroll)

            contentEl.style.transform = 'translateY(0px)'
            overlayEl.style.opacity = '0'
        }
    }

    useEffect(() => {
        const el = wrapper.current as any

        if (!el) {
            return
        }

        return initScroll(el)
    })

    return (
        <div className={'hero-scroll'} ref={wrapper}>
            <div className={'hero-scroll__content'} ref={content}>{props.children}</div>
            {props.parallax && <div className={'hero-scroll__overlay'} ref={overlay} />}
        </div>
    )
}

export default HeroScroll
