import { FontStyle } from '../types'
import {ReactElement} from "react";

export type HeroTextProps = {
    children?: ReactElement | ReactElement[] | any[];
    textAlign?: HeroTextAlign
    preHeadingFont?: FontStyle
    teaserTextFont?: FontStyle
}

export enum HeroTextAlign {
    Left = 'left',
    Center = 'center',
    Right = 'right',
}
