import React, { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Props } from './types'

const VideoControls = React.forwardRef<HTMLVideoElement, Props>(
    (props, ref) => {
        const { t } = useTranslation()

        const [playing, setPlaying] = useState(true)

        const [pressed, setPressed] = useState(false)

        function playVideo(videoRef: any) {
            setPlaying(true)
            videoRef.current?.play()
        }

        function pauseVideo(videoRef: any) {
            setPlaying(false)
            videoRef.current?.pause()
        }

        function togglePlayPause(event: MouseEvent<HTMLButtonElement>) {
            event.preventDefault()
            if (!ref) return
            setPressed(!pressed)
            playing ? pauseVideo(ref) : playVideo(ref)
        }

        return (
            <div className={`video-controls ${props.className}`}>
                <button
                    className={`video-controls__play-button ${playing ? 'playing' : 'paused'}`}
                    aria-pressed={pressed}
                    aria-label={
                        playing
                            ? t('global.pauseVideo')
                            : t('global.startVideo')
                    }
                    onClick={togglePlayPause}
                >
                    <span className="ctrl-btn"></span>
                    <span className="ctrl-text">
                        {playing ? t('global.pause') : t('global.start')}
                    </span>
                </button>
            </div>
        )
    }
)

VideoControls.displayName = 'VideoControls'

export default VideoControls
